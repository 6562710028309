<template>
    <div class="layout-rightpanel" @click="updateRightMenuClick">
        <div class="rightpanel-wrapper">

            <!--TÍTULOS-->
            
            <div v-if="state.editUser === 1" class="flex justify-content-center align-items-center gap-3 titulo card text-center font-bold text-xl">
                <span>Informações do Usuário</span>

                <div v-if="state.editUser === 1" class="editar-usuario">
                    <Button @click="proximoPasso()" class="p-button-primary p-button-sm" icon="pi pi-pencil" v-tooltip.top="'Editar'"/>
                </div>
            </div>

            <div v-if="state.editUser === 2" class="titulo card text-center font-bold text-xl">Editar Informações</div>

            <div v-if="state.editUser === 3" class="titulo card text-center font-bold text-xl">Alterar senha</div>
    
            

            <!--CARD USUARIO-->
            <div class="card border-round">

                <!--INFORMACOES USUARIO-->
                <div v-if="state.editUser === 1" class="flex flex-column gap-4">
                    <div class="flex justify-content-between">
                        <div class="font-medium">ID</div>
                        <div>{{usuario.id}}</div>
                    </div>
                    
                    <div class="flex justify-content-between">
                        <div class="font-medium">LOGIN</div>
                        <div>{{usuario.login}}</div>
                    </div>

                    <div class="flex justify-content-between">
                        <div class="font-medium">NOME</div>
                        <div>{{usuario.nome}}</div>
                    </div>

                    <div v-if="!isAdmin" class="flex justify-content-between">
                        <div class="font-medium">TELEFONE</div>
                        <div>{{usuario.telefone}}</div>
                    </div>
                </div>


                <!--EDITAR INFORMACOES-->
                <div class="mb-5">
                    <div v-if="state.editUser === 2">
                        <div class="mb-3">
                            <div class="font-medium mb-1">Login:</div>
                            <span class="block p-input-icon-right">
                                <i class="pi pi-lock" />
                                <InputText class="p-inputtext-sm w-full" disabled v-model="usuario.login"></InputText>
                            </span>
                        </div> 

                        <div class="mb-3">
                            <div class="font-medium mb-1">Nome:</div>
                            <InputText class="p-inputtext-sm w-full" v-model="state.user.nome" :class="{'p-invalid': !state.user.nome && state.campoInvalido}"/>
                        </div>
                    
                        <div v-if="!isAdmin" class="mb-3">
                            <div class="font-medium mb-1">Telefone:</div>
                            <InputMask mask="(99) 99999-9999" class="p-inputtext-sm w-full" v-model="state.user.telefone" :class="{'p-invalid': !state.user.telefone && state.campoInvalido}"/>
                        </div>

                        <span style="color:rgb(72, 121, 253); cursor: pointer;" @click="proximoPasso()">Alterar senha</span>
                    </div>
                    


                    <!--ALTERAR SENHA-->
                    <div v-if="state.editUser === 3" class="mb-5">
                        <div class="mb-3">
                            <div class="font-medium mb-1">Nova Senha:</div>
                            <InputText class="p-inputtext-sm w-full" v-model="state.user.senha" :class="{'p-invalid': state.user.senha !== state.confirmacaoSenha && state.campoInvalido}"/>
                        </div>

                        <div class="mb-3">
                            <div class="font-medium mb-1">Confirmar Senha:</div>
                            <InputText class="p-inputtext-sm w-full" v-model="state.confirmacaoSenha" :class="{'p-invalid': state.user.senha !== state.confirmacaoSenha && state.campoInvalido}"/>
                        </div>  
                    </div>

                </div>


                <!--BOTÕES EDITAR INFORMACOES-->
                <div class="btn-editar-informacoes">

                    <div v-if="state.editUser === 2" class="editar-usuario flex align-items-center justify-content-center gap-2">
                        <Button @click="voltarPasso()" class="p-button-primary p-button-outlined" label="Cancelar" icon="pi pi-times"></Button>
                        <Button @click="editarUsuario()" :loading="stateUsers.loading.editarUsuario" class="p-button-primary" label="Salvar" icon="pi pi-check"></Button>
                    </div>


                    <div v-if="state.editUser === 3" class="editar-usuario flex align-items-center justify-content-center gap-2">
                        <Button @click="voltarPasso()" class="p-button-primary p-button-outlined" label="Cancelar" icon="pi pi-times"></Button>
                        <Button @click="alterarSenha()" :loading="stateUsers.loading.alterarSenha" class="p-button-primary" :disabled="!state.user.senha || !state.confirmacaoSenha" label="Salvar" icon="pi pi-check"></Button>
                    </div>

                </div>

            </div>

            
            <!--BOTÃO SAIR-->
            <div class="logout-btn flex justify-content-end">
                <Button @click="logout()" label="Sair" icon="pi pi-sign-out" class="p-button-danger"></Button>
            </div>

        </div>
    </div>
</template>
  
<script>
    import { useStore } from 'vuex'
    import services from './services'
    import { useRouter } from 'vue-router'           
    import { computed,  watch, reactive } from 'vue'
    import useNotificationToast from './composables/useNotificationToast'
import useUsers from './composables/useUsers'

    export default{

        name: "AppRightPanel",
        emits: ["right-menu-click", "update:rightMenuClick"],
        props: {rightMenuActive: Boolean,},
        
        setup(props, { emit }) {

            const updateRightMenuClick = () => {
                emit('update:rightMenuClick', true)
                emit('right-menu-click', true)
            }

            const state = reactive({

                editUser: 1,

                user:{
                    nome: '',
                    senha:'',
                    telefone:''
                },
                confirmacaoSenha: '',

                campoInvalido: false
            })


            const store = useStore()
            const router = useRouter()
            const isAdmin = computed(()=> store.getters.isAdmin)
            const usuario = computed(()=> store.getters.getUsuario)
            

            //composables
            const { stateUsers } = useUsers()
            const { success, error} = useNotificationToast()


            //observa se o painel do usuario está on/off
            watch(() => props.rightMenuActive, (newVal) => {
                if (!newVal) {
                    state.editUser = 1
                }
            })



            //edição do usuário
            async function editarUsuario(){

                stateUsers.loading.editarUsuario = true
                state.campoInvalido = true

                switch (true) {
                    case (isAdmin.value && !state.user.nome):
                        stateUsers.loading.editarUsuario = false
                        return error('Não deixe os campos vazios')
        
                    case (!isAdmin.value && (!state.user.telefone || !state.user.nome)):
                        stateUsers.loading.editarUsuario = false
                        return error('Não deixe os campos vazios')

                    default: break      
                }

                const token = store.getters.getToken

                if(isAdmin.value === true){
                    state.user = {
                        id: usuario.value.id,
                        nome: state.user.nome,
                    }
                }

                state.user = {
                    id: usuario.value.id,
                    nome: state.user.nome,
                    telefone: state.user.telefone
                }

                await services.users.updateUser({user:state.user,token})
                .then((response)=>{
                    success(response.message)
                    state.editUser = 1
                    usuario.value.nome = state.user.nome
                    usuario.value.telefone = state.user.telefone
                })
                .catch((e)=>{
                    error(e)
                })
                .finally(()=> {
                    state.campoInvalido = false
                    stateUsers.loading.editarUsuario = false
                })
            }


            
            //atlerar senha do usuário
            async function alterarSenha(){

                stateUsers.loading.alterarSenha = true
                state.campoInvalido = true

                const token = store.getters.getToken

                state.user = {
                    login : usuario.value.login,
                    senha: state.user.senha,
                    confirmacaoSenha: state.confirmacaoSenha
                }

                await services.auth.changePassword({user:state.user,token})
                .then((response)=>{
                    success(response.message)
                    state.editUser = 1
                })
                .catch((e)=>{
                    if(state.user.senha !== state.confirmacaoSenha){
                        error('As senhas não coincidem.')
                    }
                    else{
                        error(e)
                    }
                })
                .finally(()=>{
                    stateUsers.loading.alterarSenha = false
                })
            }



            //passa para proxima informação
            function proximoPasso(){
                state.editUser++
                state.campoInvalido = false
                limparCampos()
            }

            //volta para informação anterior
            function voltarPasso(){
                state.editUser--
                state.campoInvalido = false
                limparCampos()
            }

            //limpa os campos dos inputs
            function limparCampos(){
                state.user.senha = ''
                state.confirmacaoSenha = ''
                state.user.nome = usuario.value.nome
                state.user.telefone = usuario.value.telefone
            }

            //logout
            function logout(){
                router.push({name: 'login'})

                store.dispatch('setLogged', {
                    isLogged: false
                })
            }


            return {
                updateRightMenuClick,
                logout,
                alterarSenha,
                proximoPasso,
                voltarPasso,
                editarUsuario,
                limparCampos,
                stateUsers,
                state,
                usuario,
                isAdmin
            }
        }
    }

</script>
