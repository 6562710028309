import services from '../services'
import { reactive } from 'vue'

export default function () {
    
    const state = reactive({
        cartelas: [],
        pesquisarCartela:'',

        loading:{
           carregarCartela: false
        },

        errorMessage: null
    })

    
    async function carregarCartelas() {

        state.loading.carregarCartela = true

        let token = state.pesquisarCartela

        await services.cartelas.getAll({token})
        .then((response) => {
            state.cartelas = response.data
            state.errorMessage = null
        })
        .catch((error) => {
            if (error.response && error.response.status === 422) {
                state.errorMessage = "Nenhuma cartela encontrada."
                return state.cartelas = []
            }
            state.loading.carregarCartela = false
        })
        .finally(()=>{
            state.loading.carregarCartela = false
        })
    }

    return {
        stateCartelas: state,
        carregarCartelas,
    }
}